
  import { defineComponent } from 'vue'
  import { RadioStation, UnsupportedOperationError } from '@/shared/api'
  import ContentLoader from '@/shared/components/ContentLoader.vue'
  import EditModal from '@/shared/components/EditModal.vue'
  import TrackList from '@/library/track/TrackList.vue'

  export default defineComponent({
    components: {
      TrackList,
      ContentLoader,
      EditModal,
    },
    data() {
      return {
        items: null as null | RadioStation[],
        unsupported: false,
        modalVisible: false,
        editItem: {},
      }
    },
    async created() {
      try {
        this.items = await this.$api.getRadioStations()
      } catch (err) {
        if (err instanceof UnsupportedOperationError) {
          this.items = []
          this.unsupported = true
          return
        }
        throw err
      }
    },
    methods: {
      openAddModal() {
        this.editItem = {}
        this.modalVisible = true
      },
      openEditModal(item: RadioStation) {
        this.editItem = item
        this.modalVisible = true
      },
      deleteRadioStation(item: RadioStation) {
        this.$api.deleteRadioStation(item.id)
        this.items = this.items!.filter(x => x.id !== item.id)
      },
      async saveRadioStation(item: RadioStation) {
        this.editItem = {}
        if (item.id) {
          this.items = await this.$api.updateRadioStation(item)
        } else {
          this.items = await this.$api.addRadioStation(item.title, item.url, item.description)
        }
      },
    },
  })
